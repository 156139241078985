export default {
  data: () => ({
    // Header default value for HTTP request
    defaultHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Client-Application-Version': '1.0',
      'Client-Application-Name': 'slodim',
    },

    apiRoot: process.env.VUE_APP_API_ROOT,
  }),

  methods: {
    // Wrapper for fetch() with POST method and default headers
    post(url, content, headers = this.defaultHeaders) {
      return fetch(`${url}`, { method: 'POST', headers, body: content });
    },

    // Wrapper for fetch() with PUT method and default headers
    put(url, content, headers = this.defaultHeaders) {
      return fetch(`${url}`, { method: 'PUT', headers, body: content });
    },

    // Wrapper for fetch() with DELETE method
    delete(url, headers = this.defaultHeaders) {
      return fetch(`${url}`, { method: 'DELETE', headers });
    },

    // Wrapper for fetch() with GET method
    get(url) {
      return fetch(`${url}`);
    },
  },
};
