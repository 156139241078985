import MixinCommon from './common';

export default {
  mixins: [MixinCommon],
  data: () => ({
    // Show/hide password in text field
    password: { visible: false },
  }),

  methods: {

    // Log user in
    async authenticateUser(data) {
      const response = await this.post('/login', JSON.stringify(data));

      if (response.ok) {
        this.$emit('user-is-connected', true); // send to router-view in App
        if (this.$route.query.redirectFrom) {
          this.$router.push(this.$route.query.redirectFrom);
        } else {
          this.$router.push('/profile');
        }
      } else if (response.status === 404) {
        this.showAlertMessage('error', 'Identifiants incorrects (utilisateur non existant)');
      } else if (response.status === 400) {
        this.showAlertMessage('error', await response.text());
      } else if (response.status === 403) {
        this.showAlertMessage('error', 'Login ou mot de passe incorrect');
      } else {
        this.showAlertMessage('error', 'Erreur interne');
      }
    },

    // Return current user information from server
    async getUserInfo() {
      const response = await fetch(`${this.apiRoot}/user`);
      const data = response.ok ? await response.json() : null;

      return data;
    },

    // Create a new user
    async createUser(data) {
      const response = await this.post('/user', JSON.stringify(data));

      if (response.ok) {
        const user = await response.json();
        this.showAlertMessage('success', 'Le compte a bien été créé. Redirection en cours...');
        setTimeout(() => this.$router.push(`/login?u=${user.email}`), 2100);
      } else {
        this.showAlertMessage('error', await response.text());
      }
    },

    // Update current user information
    async updateUser(data, redirect = true) {
      const response = await this.put(`${this.apiRoot}/user`, data);

      if (response.ok && redirect) {
        this.$router.push('/profile');
      } else {
        const error = await response.text();
        this.sendNotification(error);
      }
    },

    // Get user patient/interviews tree
    async getUserTree() {
      const response = await this.get(`${this.apiRoot}/user/tree`);

      if (!response.ok) {
        const error = await response.text();
        this.sendNotification(error);
        return [];
      }

      const data = await response.json();

      return data;
    },

    // Delete current user
    async deleteUser() {
      const response = await this.delete(`${this.apiRoot}/user`);

      if (response.ok) {
        this.$router.push('/');
      } else {
        this.showAlertMessage('error', await response.text());
      }
    },
  },
};
