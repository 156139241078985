import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
      ],
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', disabled: true },
      ],
    },
  },
  {
    path: '/transversal',
    name: 'Transversal',
    component: () => import('../views/Transversal.vue'),
    children: [{
      name: 'General',
      path: '/',
      component: () => import('../components/transversal/Analysis.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Aperçu des analyses', disabled: true },
        ],
      },
    },
    {
      path: 'groups',
      name: 'Significance',
      component: () => import('../components/transversal/Group.vue'),
      props: { type: 'interview' },
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Étude par groupe', disabled: true },
        ],
      },
    },
    {
      path: 'interviews',
      name: 'InterviewsComparison',
      component: () => import('../components/transversal/InterviewsComparison.vue'),
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Comparaison d\'interviews', disabled: true },
        ],
      },
    },
    {
      path: 'prosody',
      name: 'ProsodyMultiple',
      component: () => import('../components/transversal/Prosody.vue'),
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Prosodie', disabled: true },
        ],
      },
    }],
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
      ],
    },
  },
  {
    path: '/patient/:patientId/neurotest/PANSS/:neurotestId',
    name: 'PANSS',
    component: () => import('../views/TestPanss.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
        { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
        { text: 'Test neurocognitif - PANNS', disabled: true },
      ],
    },
  },
  {
    path: '/patient/:patientId/neurotest/TMT/:neurotestId',
    name: 'TestTrailMaking',
    component: () => import('../views/TestTrailMaking.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
        { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
        { text: 'Test neurocognitif - TMT', disabled: true },
      ],
    },
  },
  {
    path: '/profile/update',
    name: 'ProfileUpdate',
    component: () => import('../views/Update.vue'),
    props: { type: 'user' },
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
        { text: 'Mise à jour du profil', disabled: true },
      ],
    },
  },
  {
    path: '/patient/:patientId',
    name: 'Patient',
    component: () => import('../views/Patient.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
        { text: 'Dossier patient', disabled: true },
      ],
    },
  },
  {
    path: '/patient/:patientId/corpus',
    name: 'Corpus',
    component: () => import('../components/common/forms/MultipleUpload.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
        { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
        { text: 'Import un corpus', disabled: true },
      ],
    },
  },
  {
    path: '/patient/:patientId/update',
    name: 'PatientUpdate',
    component: () => import('../views/Update.vue'),
    props: { type: 'patient' },
    meta: {
      breadcrumb: [
        { text: 'Tableau de bord', to: '/profile', exact: true },
        { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
        { text: 'Mise à jour du dossier patient', disabled: true },
      ],
    },
  },
  {
    path: '/patient/:patientId/interview/:interviewId',
    component: () => import('../views/Interview.vue'),
    props: true,
    children: [{
      name: 'Upload',
      path: 'upload/:type',
      component: () => import('../components/common/forms/Upload.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Upload', disabled: true },
        ],
      },
    },
    {
      path: 'update',
      name: 'InterviewUpdate',
      component: () => import('../views/Update.vue'),
      props: { type: 'interview' },
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Mise à jour de l\'entretien', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolBasics',
      path: 'tool/basics',
      component: () => import('../components/interviews/tools/Basics.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Analyse basique', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolTranscription',
      path: 'tool/text-to-speech',
      component: () => import('../components/interviews/tools/Transcription.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Transcription', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolSyntax',
      path: 'tool/syntax',
      component: () => import('../components/interviews/tools/Syntax.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Analyse syntaxique', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolEmbeddings',
      path: 'tool/embeddings',
      component: () => import('../components/interviews/tools/Embeddings.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Plongement', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolDialogic',
      path: 'tool/dialogic',
      component: () => import('../components/interviews/tools/Dialogic.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Parsing dialogique', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolSemantic',
      path: 'tool/semantic',
      component: () => import('../components/interviews/tools/Semantic.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Analyse sémantique', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolDiscursive',
      path: 'tool/discursive',
      component: () => import('../components/interviews/tools/Discursive.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Parsing discursif', disabled: true },
        ],
      },
    },
    {
      name: 'Prosody',
      path: 'tool/prosody',
      component: () => import('../components/interviews/tools/Prosody.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Prosodie', disabled: true },
        ],
      },
    },
    {
      name: 'InterviewToolExport',
      path: 'tool/export',
      component: () => import('../components/interviews/Export.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', to: '/patient/:patientId/interview/:interviewId', exact: true },
          { text: 'Exporter les résultats', disabled: true },
        ],
      },
    },
    {
      path: '/',
      component: () => import('../components/interviews/Dashboard.vue'),
      props: true,
      meta: {
        breadcrumb: [
          { text: 'Tableau de bord', to: '/profile', exact: true },
          { text: 'Dossier patient', to: '/patient/:patientId/', exact: true },
          { text: 'Entretien', disabled: true },
        ],
      },
    }],
  },
  {
    path: '/recorder',
    name: 'Recorder',
    component: () => import('../views/Recorder.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const apiRoot = process.env.VUE_APP_API_ROOT;
  const response = await fetch(`${apiRoot}/user`);
  const isAuthenticated = (response.status === 200);
  const freePages = ['Login', 'Home', 'Signup', 'NotFound', 'Recorder'];
  if ((to.name === 'Home' || to.name === 'Login') && isAuthenticated) {
    next({ name: 'Profile' });
  } else if (!freePages.includes(to.name) && !isAuthenticated) {
    next({
      name: 'Login',
      query: { redirectFrom: to.fullPath, authenticationNeeded: true },
    });
  } else {
    next();
  }
});

export default router;
