import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.filter('round', (value) => {
  if (!value) return '0.0';
  return Math.round(value * 100) / 100;
});

Vue.filter('truncate', (text, stop) => (
  text.slice(0, stop) + (stop < text.length ? '...' : '')
));

Vue.filter('timify', (time) => {
  function pad(n, z) {
    const a = z || 2;
    return `00${n}`.slice(-a);
  }

  const ms = time % 1000;
  let s = (time - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  // const hrs = (s - mins) / 60;

  return `${pad(mins)}:${pad(secs)}.${pad(ms, 3)}`;
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
