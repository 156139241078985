import validator from 'validator';
import MixinRequest from './request';

export default {
  mixins: [MixinRequest],
  data: () => ({
    alert: { visible: false, msg: '', type: 'error' },

    // Rules for email validation
    rules: {
      basic: [
        (v) => (v.length <= 64) || 'Le nom ne doit pas dépasser 256 caractères',
      ],
      email: [
        (v) => (!!v) || 'Champ obligatoire',
        (v) => (validator.isEmail(v)) || 'Ne correspond pas une addresse valide',
      ],
      name: [
        (v) => (v.length <= 64) || 'Le nom ne doit pas dépasser 64 caractères',
      ],
      select: [
        (v) => (!!v) || 'Champ obligatoire',
      ],
      password: [
        (v) => (!!v) || 'Champ obligatoire',
        (v) => (v && v.length <= 64) || 'Le nom ne doit pas dépasser 64 caractères',
      ],
      initials: [
        (v) => (!v || v.length <= 3) || 'Les initiales doivent être composées de 3 caractères maximum',
        (v) => (!v || validator.isUppercase(v)) || 'Les initiales doivent être entrées en majuscule',
        (v) => (!v || validator.isAlpha(v)) || 'Les initiales ne peuvent pas contenir de caractères spéciaux',
      ],
      number: [
        (v) => (!v || validator.isNumeric(v)) || 'Le numéro du sujet ne peut contenir que des chiffres',
      ],
      city: [
        (v) => (!v || v.length <= 64) || 'La commune de naissance ne doit pas dépasser 64 caractères',
        (v) => (!v || validator.matches(v, /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/)) || 'Caractères spéciaux interdits)',
      ],
      profession: [
        (v) => (!v || v.length <= 64) || 'La profession ne doit pas dépasser 64 caractères',
      ],
    },
  }),

  methods: {
    // Display alert message at top of the forms
    showAlertMessage(type, message) {
      this.alert.type = type;
      this.alert.visible = true;
      this.alert.msg = message;
    },

    sendNotification(msg) {
      this.$emit('notify', msg);
    },

    // Plural
    s(entities) {
      return (entities.length > 1) ? 's' : '';
    },
  },
};
