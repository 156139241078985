<template>
  <v-app>
    <!--<v-navigation-drawer v-show="false" v-model="drawer" app>
      <v-list dense>
        <v-list-item link to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Page d'accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item link to="/profile">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Tableau de bord</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Déconnexion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>-->

    <v-app-bar app color="white" flat dense>
      <!--<v-app-bar-nav-icon @click.stop="drawer = !drawer"/>-->
      <v-toolbar-title light class="font-weight-bold indigo--text ">SLODiM</v-toolbar-title>
      <v-breadcrumbs light :items="breadcrumbItems">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>

      <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
        <template v-slot:activator="{ on }">
          <v-chip dark v-show="userIsConnected" pill color="blue" v-on="on">
            <v-avatar left>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>{{ user.name }}
            <v-avatar right>
              <v-icon small>mdi-chevron-down</v-icon>
            </v-avatar>
          </v-chip>
        </template>
        <v-card width="300">
          <v-list light dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon x-large color="primary">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="menu = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list dense>
            <v-list-item to="/profile">
              <v-list-item-action>
                <v-icon color="blue">mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>Tableau de bord</v-list-item-subtitle>
            </v-list-item>
            <v-list-item to="/settings">
              <v-list-item-action>
                <v-icon color="greey">mdi-view-list</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>Paramètres</v-list-item-subtitle>
            </v-list-item>
            <v-list-item to="/about">
              <v-list-item-action>
                <v-icon color="grey">mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>À propos</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-action>
                <v-icon color="red">mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>Déconnexion</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-show="!userIsConnected" icon to="/login" v-on="on">
            <v-icon>mdi-account-key</v-icon>
          </v-btn>
        </template>
        <span>Se connecter</span>
      </v-tooltip>
    </v-app-bar>
    <v-content>
      <!-- FIXME: utiliser la couleur 'primary'-->
      <v-container style="border-top: 1px solid #0275d8;" class="fill-height" fluid>
          <router-view
            class="mt-1"
            @notify="snack"
            v-on:user-is-connected="updateShortcuts">
          </router-view>
      </v-container>
      <v-snackbar top v-model="snackbar" :timeout="10000">{{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon></v-btn>
        </template>
      </v-snackbar>
    </v-content>
  </v-app>
</template>

<script>
import MixinRequest from '@/mixins/request';
import MixinUser from '@/mixins/users';

export default {
  mixins: [MixinRequest, MixinUser],
  data: () => ({
    drawer: false,
    message: '',
    snackbar: false,
    userIsConnected: false,
    user: {},
    menu: false,
    breadcrumbItems: [],
  }),

  watch: {
    async $route() {
      const data = await this.getUserInfo();

      if (data) {
        this.user.name = data.name;
        this.user.email = data.email;
        this.userIsConnected = true;
      } else {
        this.updateShortcuts(false);
      }

      if (!this.$route.meta.breadcrumb) {
        this.breadcrumbItems = [];
        return;
      }

      this.breadcrumbItems = this.$route.meta.breadcrumb.map(
        (x) => {
          if (!x.to) return x;

          // To prevent aliasing
          const item = JSON.parse(JSON.stringify(x));

          item.to = item.to.replace(/:patientId/, this.$route.params.patientId);
          item.to = item.to.replace(/:interviewId/, this.$route.params.interviewId);
          item.to = item.to.replace(/:neurotestId/, this.$route.params.neurotestId);

          return item;
        },
      );
    },
  },

  methods: {

    async logout() {
      await this.get(`${this.apiRoot}/user/logout`);
      this.$router.push('/', () => {});
      this.updateShortcuts(false);
    },

    snack(e) {
      this.snackbar = true;
      this.message = e;
    },

    async updateShortcuts(userIsConnected) {
      if (userIsConnected) {
        const data = await this.getUserInfo();
        this.user.name = data.name;
        this.user.email = data.email;
      }
      this.userIsConnected = userIsConnected;
    },
  },
};
</script>
